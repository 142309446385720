<template>
  <div class="about" @click="handleDownload">
    <browser-cover @onAutoDownload="onAutoDownload" />
    <img
      class="img"
      src="https://cdn.qiandaoapp.com/admins/d27aa62e0d6acf9580d45066e582e24f.png"
    />
    <copy-right />
  </div>
</template>

<script setup>
import { reactive, toRefs, watch, ref } from "vue";
import axios from "axios";
import { isProd } from "@/utils/env-related";
import BrowserCover from "@/components/BrowserCover";
import CopyRight from "@/components/CopyRight";
const isIos = ref();
const downloadLink = ref();

const handleDownload = () => {
  // if (isIos.value) {
  //   alert("即将推出");
  //   return;
  // }
  const a = document.createElement("a");
  a.href = downloadLink.value;
  a.click();
};

const checkIsIos = () => {
  return /(iPhone|iPad|iPod|iOS)/i.test(window.navigator.userAgent);
};

const onAutoDownload = () => {
  setTimeout(() => {
    handleDownload();
  }, 1000);
};

const getDownloadLink = async () => {
  let res = "";
  if (isProd) {
    res = await axios.get(
      "https://config-cdn.echoing.tech/linjie-web/default.json"
    );
  } else {
    res = await axios.get(
      "https://dev-config-cdn.echoing.tech/linjie-web/default.json"
    );
  }

  if (res.data) {
    const { androidDownloadLink, iosDownloadLink } = res.data;
    if (isIos.value) {
      downloadLink.value = iosDownloadLink;
    } else {
      downloadLink.value = androidDownloadLink;
    }
  } else {
    alert("下载链接获取失败");
  }
};

isIos.value = checkIsIos();
getDownloadLink();
</script>

<style lang="scss" scoped>
.about {
  position: relative;
  .img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
}
</style>
