<template>
  <div class="copyright">
    <div @click="handleClick1">沪ICP备2021012788号-2</div>
    <div class="beian2" @click="handleClick2">
      <img
        class="gongan-icon"
        src="https://cdn.qiandaoapp.com/interior/images/228e58cb1585de9884d77635d06eafaa.png"
      />
      <span style="">沪公网安备 31010702007751号</span>
    </div>
    <span>上海啸翼网络科技有限公司</span>
    <span>
      <a href="https://config-cdn.qiandaoapp.com/docs/enterprise_linjie.html"
        >企业资质</a
      >
    </span>
  </div>
</template>

<script setup>
const handleClick1 = (e) => {
  e.stopPropagation();
  e.preventDefault();
  return;
};
const handleClick2 = (e) => {
  e.stopPropagation();
  e.preventDefault();
  window.open(
    "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010702007751"
  );
};
</script>

<style lang="scss" scoped>
.copyright {
  width: 100%;
  padding: 20rpx;
  text-align: center;
  color: white;
  font-size: 24rpx;
  background-color: #3c3c3c;
  .gongan-icon {
    width: 36rpx;
  }
  span,
  div {
    margin-right: 8rpx;
    a {
      color: white;
    }
  }
  .beian2 {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 36rpx;
  }
}
</style>
